import React from 'react';
import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';
import { Carousel } from 'antd';

import HomeHeader from '../components/HomeHeader';
// import { navigate } from 'gatsby';
import debug from '../utils/debug';
import { specialData } from '../utils/special';

const styles = (): StyleRules =>
  createStyles({
    root: {
      //   width: 280,
      width: '100%',
      overflow: 'hidden',
      //   marginTop: 200,
    },
    menuContent: {
      height: '15rem',
      fontSize: '1rem',
      overflow: 'auto',
      background: '#f2f4f8',
      padding: '1rem',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      scrollbarWidth: 'none',
      '-ms-overflow-style': 'none',
      overflowY: 'scroll',
    },
    hotRecruit: {
      padding: '1rem 5rem',
      textAlign: 'center',
      fontSize: '1.2rem',
      color: '#35354b',
    },
    listMenu: {
      cursor: 'pointer',
      margin: '1rem',
    },
    centerContent: {
      padding: '0 20%',
      textAlign: 'center',
      display: 'flex',
    },
    div: {
      width: '10rem',
      height: '5rem',
      margin: '1rem',
      borderRadius: 2,
      background: '#f8f9fa',
    },
    h3: {
      textAlign: 'center',
    },
  });
const contentStyle: React.CSSProperties = {
  height: '15rem',
  color: '#fff',
  lineHeight: '15rem',
  textAlign: 'center',
  background: '#364d79',
};
const IndexPage = ({ classes }: Props): React.ReactElement => {
  debug('Loading index page');
  //   const { t } = useTranslation();
  //   i18n.loadNamespaces('index');

  return (
    <>
      <HomeHeader></HomeHeader>
      <p className={classes.hotRecruit}>
        热门职位：人工智能工程师
        、数据科学家、ESG商业分析师、区块链开发者、网络安全工程师、新能源与环保工程师、健康与福祉顾问、电子商务经理
      </p>
      <Grid container direction="row" justifyContent="center" spacing={1} className={classes.root}>
        <Grid item xs={3} className={classes.menuContent}>
          {specialData.map((item, index) => {
            return (
              <div key={index}>
                <div className={classes.listMenu}>{item.label}</div>
              </div>
            );
          })}
        </Grid>
        <Grid item xs={6}>
          <Carousel autoplay>
            <div>
              <h3 style={contentStyle}>1</h3>
            </div>
            <div>
              <h3 style={contentStyle}>2</h3>
            </div>
            <div>
              <h3 style={contentStyle}>3</h3>
            </div>
            <div>
              <h3 style={contentStyle}>4</h3>
            </div>
          </Carousel>
        </Grid>
      </Grid>
      <h3 className={classes.h3}>推荐</h3>
      <div className={classes.centerContent}>
        {[1, 2, 3, 4, 5].map(item => {
          return (
            <div key={item} className={classes.div}>
              {item}
            </div>
          );
        })}
      </div>
      <h3 className={classes.h3}>月度热门</h3>
      <div className={classes.centerContent}>
        {[1, 2, 3, 4, 5].map(item => {
          return (
            <div key={item} className={classes.div}>
              {item}
            </div>
          );
        })}
      </div>
      <h3 className={classes.h3}>热门企业</h3>
      <div className={classes.centerContent}>
        {[1, 2, 3, 4, 5].map(item => {
          return (
            <div key={item} className={classes.div}>
              {item}
            </div>
          );
        })}
      </div>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(IndexPage);
